import React, { useState } from 'react'
import {
  Checkbox,
  Popover,
  PopoverTrigger,
  PopoverContent,
  InputGroup,
  InputLeftElement,
  Input,
  Tooltip,
} from '@chakra-ui/react'
import styled from 'styled-components'
import { truncateString } from '../utils'
import { Search } from 'react-feather'
import { FixedSizeList as List } from 'react-window'

const Option = styled.div<any>`
  height: 40px;
  min-height: 40px;
  font-size: 13px;
  font-weight: 500;
  padding-left: 8px;
  padding-right: 15px;
  color: rgb(77, 77, 77);
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  background-color: ${props => (props.isSelected ? '#f8f8f8' : '')};

  &:hover {
    background-color: #f8f8f8;
  }
`

export const Selection = styled.div<any>`
  background-color: white;
  border: 1px solid rgb(226, 232, 240);
  max-width: ${props => props.maxWidth};
  min-height: 40px;
  border-radius: 6px;
  display: flex;
  align-items: flex-start;
  justify-content: ${props => (props.showPlaceholder ? 'flex-start' : 'left')};
  flex-wrap: wrap;
  padding: 5px;
  &:hover {
    border-color: black;
    cursor: pointer;
  }
  ::placeholder {
    font-size: 15px;
  }
`

export const Pill = styled.span`
  display: flex;
  align-items: center;
  padding: 4px 12px;
  margin-left: 5px;
  background-color: ${props => props.color || '#e0e0e0'};
  border-radius: 6px;
  font-size: 12px;
  &:first-child {
    margin-left: 0;
  }
`

export const PillsWrapper = styled.td`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 5px;
  padding: 5px 0;
`

function fieldLabelText(fieldName: string): string {
  if (typeof fieldName !== 'string' || !fieldName) {
    return ''
  }

  switch (fieldName) {
    case 'Owner ID':
      return 'Owner'
    case 'Created By ID':
      return 'Created By'
    case 'Contact ID':
      return 'Contact Name'
    case 'Account ID':
      return 'Account Name'
    default:
      return fieldName
  }
}
const FieldMultiSelect = ({
  fields,
  selections,
  onSelect,
  menuWidth = '428px',
  maxWidth = '428px',
  placeholder = 'Fields to include in alert (optional)',
  useVirtualizedRendering = false,
  useToolTip = false,
}: any) => {
  const [term, setTerm] = useState('')

  const filteredFields = fields.filter((field: any) => {
    const searchTerm = term.toLowerCase()

    return (
      field?.name.toLowerCase().includes(searchTerm) ||
      field?.label.toLowerCase().includes(searchTerm)
    )
  })

  const Row = ({ index, style }: { index: number; style: any }) => {
    const field = filteredFields[index]
    return (
      <>
      {useToolTip && (
        <Tooltip 
          label={fieldLabelText(field?.label)}
          fontSize="sm"
          placement="top"
          color='#202020'
          backgroundColor='#F0F0F0'
          borderRadius='6px'
          border='solid #D9D9D9 1px'
        >
          <Option
            key={field.name}
            onClick={() => {
              onSelect(field.name)
            }}
            isSelected={selections.includes(field.name)}
            style={style}
          >
            <div style={{ paddingBottom: '15px' }}>
              <span style={{ paddingLeft: '10px', position: 'absolute' }}>
                {truncateString(fieldLabelText(field?.label), 32)}
              </span>
            </div>
            <Checkbox
              onChange={() => {
                onSelect(field.name)
              }}
              isChecked={selections.includes(field.name)}
              sx={{
                '.chakra-checkbox__control': {
                  height: '18px',
                  width: '18px',
                  borderRadius: '3px',
                  borderColor: 'black',
                  _checked: {
                    bg: 'black',
                    color: 'white',
                    borderColor: 'black',
                    svg: {
                      transform: 'scale(1.3)',
                    },
                    _hover: {
                      bg: 'black',
                      borderColor: 'black',
                    },
                  },
                  _hover: {
                    borderColor: 'black',
                  },
                  _focus: {
                    boxShadow: 'none',
                  },
                },
              }}
            />
          </Option>
        </Tooltip>
      )}
      {!useToolTip && (
        <Option
          key={field.name}
          onClick={() => {
            onSelect(field.name)
          }}
          isSelected={selections.includes(field.name)}
          style={style}
        >
          <div style={{ paddingBottom: '15px' }}>
            <span style={{ paddingLeft: '10px', position: 'absolute' }}>
              {truncateString(fieldLabelText(field?.label), 32)}
            </span>
          </div>
          <Checkbox
            onChange={() => {
              onSelect(field.name)
            }}
            isChecked={selections.includes(field.name)}
            sx={{
              '.chakra-checkbox__control': {
                height: '18px',
                width: '18px',
                borderRadius: '3px',
                borderColor: 'black',
                _checked: {
                  bg: 'black',
                  color: 'white',
                  borderColor: 'black',
                  svg: {
                    transform: 'scale(1.3)',
                  },
                  _hover: {
                    bg: 'black',
                    borderColor: 'black',
                  },
                },
                _hover: {
                  borderColor: 'black',
                },
                _focus: {
                  boxShadow: 'none',
                },
              },
            }}
          />
        </Option>
      )}
      </>
    )
  }

  const ITEM_HEIGHT = 40 // Adjust to match Option height
  const LIST_HEIGHT = 280 // Max height of your popover content

  return (
    <Popover
      placement='bottom'
      onClose={() =>
        setTimeout(() => {
          setTerm('')
        }, 500)
      }
    >
      <PopoverTrigger>
        <Selection
          showPlaceholder={selections.length === 0}
          selections={selections}
          maxWidth={maxWidth}
        >
          {selections.length > 0 && fields?.length > 0 ? (
            <PillsWrapper>
              {selections.map((selection: any, index: any) => {
                const field = fields?.find((field: any) => {
                  return field.name === selection
                })

                return (
                  <Pill
                    key={selection}
                    color={'#F0F0F0'}
                    style={{
                      marginLeft: index === 0 ? 0 : `5px`,
                    }}
                  >
                    {truncateString(fieldLabelText(field?.label), 28)}
                  </Pill>
                )
              })}
            </PillsWrapper>
          ) : (
            <div
              style={{
                color: '#A7B5C4',
                fontSize: '12.5px',
                marginLeft: '15px',
                marginTop: '5px',
              }}
            >
              {placeholder}
            </div>
          )}
        </Selection>
      </PopoverTrigger>
      <PopoverContent
        marginTop={'5px'}
        width={menuWidth}
        maxHeight={'280px'} // Adjust the max height
        boxShadow='0 8px 15px rgba(0, 0, 0, 0.2), 0 4px 6px rgba(0, 0, 0, 0.15)'
        overflowY='auto'
        _focus={{
          boxShadow:
            '0 8px 15px rgba(0, 0, 0, 0.2), 0 4px 6px rgba(0, 0, 0, 0.15)',
        }}
      >
        <InputGroup>
          <InputLeftElement pointerEvents='none'>
            <Search height={15} width={15} color='#858585' />
          </InputLeftElement>
          <Input
            value={term}
            onChange={e => {
              setTerm(e.target.value)
            }}
            sx={{
              _focus: {
                boxShadow: 'none',
                borderColor: 'rgb(226, 232, 240)',
              },
              _hover: {
                boxShadow: 'none',
                borderColor: 'rgb(226, 232, 240)',
              },
            }}
            borderRadius={'none'}
            borderTop='none'
            borderLeft='none'
            borderRight='none'
            fontSize='14px'
            placeholder='Search'
          />
        </InputGroup>

        {useVirtualizedRendering ? (
          // Virtualized rendering using react-window
          <List
            height={LIST_HEIGHT} // Total height of the list container
            itemCount={filteredFields.length} // Number of filtered items to render
            itemSize={ITEM_HEIGHT} // Height of each item (matches Option height)
            width={menuWidth} // List width
          >
            {Row}
          </List>
        ) : (
          // Standard rendering using map
          filteredFields.map((field: any) => {
            return (
              <Option
                key={field.name}
                onClick={() => {
                  onSelect(field.name)
                }}
                isSelected={selections.includes(field.name)}
              >
                <div style={{ paddingBottom: '15px' }}>
                  <span
                    style={{
                      paddingLeft: '10px',
                      position: 'absolute',
                    }}
                  >
                    {truncateString(fieldLabelText(field?.label), 32)}
                  </span>
                </div>
                <Checkbox
                  onChange={() => {
                    onSelect(field.name)
                  }}
                  isChecked={selections.includes(field.name)}
                  sx={{
                    '.chakra-checkbox__control': {
                      height: '18px',
                      width: '18px',
                      borderRadius: '3px',
                      borderColor: 'black',
                      _checked: {
                        bg: 'black',
                        color: 'white',
                        borderColor: 'black',

                        svg: {
                          transform: 'scale(1.3)',
                        },

                        _hover: {
                          bg: 'black',
                          borderColor: 'black',
                        },
                      },
                      _hover: {
                        borderColor: 'black',
                      },
                      _focus: {
                        boxShadow: 'none',
                      },
                    },
                  }}
                />
              </Option>
            )
          })
        )}
      </PopoverContent>
    </Popover>
  )
}

export default FieldMultiSelect
